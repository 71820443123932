export enum ClaimTypes {
	AppSettings = 1,
	Users = 2,
	UserRoles = 3,
	CanChangeStatus = 4,
	Audit = 5,
	AccessRequests = 6,
	Providers = 7,
	Cases,
	Encounters,
	Children,
	ChildMatches,
	Contracts,
	Invoices,
	SchoolDistricts,
	ProviderCustomDocuments,
	ClosedCaseAccess,
}
