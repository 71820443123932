import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CookieModule } from '@mt-ng2/cookie';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { ToastrModule } from 'ngx-toastr';

import { LoginConfigOverride } from '@admin/common/configs/login.config';
import { AdminPortalSharedModule } from '@admin/common/shared.module';
import { RequestSignatureComponent } from '@common/request-signature/request-signature.component';
import { environment } from '@environment';
import { AuthModule } from '@mt-ng2/auth-module';
import { MtDisableDuringHttpCallsModule } from '@mt-ng2/disable-during-http-calls';
import { EnvironmentModule, EnvironmentModuleConfigToken } from '@mt-ng2/environment-module';
import { BreckErrorHandler } from '@mt-ng2/errors-module';
import { LoginModuleConfigToken, MtLoginModule } from '@mt-ng2/login-module';
import { NotificationsModule } from '@mt-ng2/notifications-module';
import { QuillModule } from 'ngx-quill';
import TableHandler from 'quill1.3.7-table-module';
import { ResetPasswordComponent } from 'reset-password/reset-password.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IsAdminLoadGuard } from './guards/is-admin-load.guard';
import { IsProviderLoadGuard } from './guards/is-provider-load.guard';
import { InactivityInterceptor } from './interceptors/inactivity.interceptor';

@NgModule({
	bootstrap: [AppComponent],
	declarations: [AppComponent, RequestSignatureComponent, ResetPasswordComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		NgbModule,
		AdminPortalSharedModule.forRoot(),
		HttpClientModule,
		NgProgressModule.withConfig({
			color: '#ff8b56',
			spinnerPosition: 'left',
			thick: false,
		}),
		NgProgressHttpModule,
		CookieModule.withOptions({
			secure: true,
			sameSite: 'strict',
		}),
		EnvironmentModule,
		NotificationsModule,
		AuthModule.forRoot(),
		MtDisableDuringHttpCallsModule.forRoot(),
		AppRoutingModule,
		NgxMaskModule.forRoot(),
		MtLoginModule,
		ToastrModule.forRoot({
			positionClass: 'toast-top-left',
		}),
		QuillModule.forRoot({
			customModules: [{ implementation: TableHandler, path: 'modules/table' }],
		}),
	],
	providers: [
		{ provide: ErrorHandler, useClass: BreckErrorHandler },
		{ provide: LocationStrategy, useClass: HashLocationStrategy },
		{ provide: LoginModuleConfigToken, useValue: LoginConfigOverride },
		{ provide: EnvironmentModuleConfigToken, useValue: environment },
		IsAdminLoadGuard,
		IsProviderLoadGuard,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: InactivityInterceptor,
			multi: true,
		},
	],
})
export class AppModule {}
