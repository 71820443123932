import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { IEvaluationSignature } from '@model/interfaces/evaluation-signature';
import { Observable } from 'rxjs';
import { IRequestSignatureDto } from '@model/interfaces/custom/request-signature.dto';
import { IEvaluationSignatureDto } from '@model/interfaces/custom/evaluation-signature.dto';

export const emptyEvaluationSignature: IEvaluationSignature = {
	DateCreated: new Date(),
	DateModified: null,
	EvaluationId: 0,
	GuardianDateSigned: null,
	GuardianId: 0,
	GuardianSignature: null,
	GuardianSignatureOptionId: 0,
	Id: 0,
	ProviderDateSigned: null,
	ProviderId: 0,
	ProviderSignature: null,
	ResetKey: null,
	ResetKeyExpirationUtc: new Date(),
};

@Injectable({
	providedIn: 'root',
})
export class EvaluationSignatureService extends BaseService<IEvaluationSignature> {
	constructor(public http: HttpClient) {
		super('/evaluation-signatures', http, null, { entityName: 'Evaluation Signature' });
	}

	getEmptyEvaluationSignature(): IEvaluationSignature {
		return { ...emptyEvaluationSignature };
	}

	getByEvalId(evalId: number): Observable<IEvaluationSignature> {
		return this.http.get<IEvaluationSignature>(`/evaluation-signatures/eval-id/${evalId}`, {});
	}

	gatherSignature(evalId: number, providerId: number, guardianId: number): Observable<IEvaluationSignature> {
		return this.http.post<IEvaluationSignature>(`/evaluation-signatures/gather`, {
			EvalId: evalId,
			ProviderId: providerId,
			GuardianId: guardianId,
		} as IEvaluationSignatureDto);
	}

	sendRequestEmail(evalId: number, providerId: number, guardianId: number): Observable<IEvaluationSignature> {
		return this.http.post<IEvaluationSignature>(`/evaluation-signatures/send-email`, {
			EvalId: evalId,
			ProviderId: providerId,
			GuardianId: guardianId,
		} as IEvaluationSignatureDto);
	}

	getRequestSignatureData(encounterId: number, isProvider: boolean, resetKey: string): Observable<IRequestSignatureDto> {
		return this.http.post<IRequestSignatureDto>(`/evaluation-signatures/data`, {
			EncounterId: encounterId,
			ResetKey: resetKey,
			IsGatherSignature: !resetKey,
			IsProvider: isProvider,
		});
	}

	addSignature(evalId: number, isProvider: boolean, formData: FormData): Observable<void> {
		return this.http.post<void>(`/evaluation-signatures/sign/${evalId}/${isProvider ? 1 : 0}`, formData);
	}
}
