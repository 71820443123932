import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { AuthService } from '@mt-ng2/auth-module';
import { Subscription, timer } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class InactivityService {
	private inactivityTime = environment.logoutTimer;
	private inactivityTimer: Subscription;

	constructor(private authService: AuthService) {}

	startWatching(): void {
		this.resetTimer();
	}

	resetTimer(): void {
		if (this.inactivityTimer) {
			this.inactivityTimer.unsubscribe();
		}
		this.inactivityTimer = timer(this.inactivityTime).subscribe(() => {
			this.authService.logout();
		});
	}
}
