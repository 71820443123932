import { Component, ErrorHandler, Injector, NgZone, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthUserService } from '@common/auth-users/services/auth-user.service';
import { AuthConfig, AuthService } from '@mt-ng2/auth-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { EnvironmentService } from '@mt-ng2/environment-module';
import { LoginConfig } from '@mt-ng2/login-module';
import { NotificationsService } from '@mt-ng2/notifications-module';

interface IResetPasswordForm {
	Password: FormControl<string | null>;
	ConfirmPassword: FormControl<string | null>;
}

@Component({
	selector: 'mt-reset-password',
	templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit {
	resetPasswordForm: FormGroup<IResetPasswordForm>;
	usernameForm: UntypedFormGroup;
	logoFull: string;
	userId?: number;
	resetKey?: string;
	username?: boolean;
	includeUsername = false;

	protected authUserService: AuthUserService;
	protected authService: AuthService;
	protected authConfig: AuthConfig;
	protected router: Router;
	public route: ActivatedRoute;
	protected notificationsService: NotificationsService;
	protected environmentService: EnvironmentService;
	public config: LoginConfig;
	protected globalErrorHandler: ErrorHandler;
	private _ngZone = inject(NgZone);

	constructor(injector: Injector) {
		this.authUserService = injector.get(AuthUserService);
		this.authService = injector.get(AuthService);
		this.authConfig = injector.get(AuthConfig);
		this.router = injector.get(Router);
		this.route = injector.get(ActivatedRoute);
		this.notificationsService = injector.get(NotificationsService);
		this.environmentService = injector.get(EnvironmentService);
		this.config = injector.get(LoginConfig);
		this.globalErrorHandler = injector.get(ErrorHandler);
		this.logoFull = this.config.useAssetsFolderForImages
			? `${this.environmentService.config.assetsPath}logo-full.png`
			: `${this.environmentService.config.imgPath}logo-full.png`;

		this.resetPasswordForm = new FormGroup({
			Password: new FormControl('', [(c) => Validators.required(c), Validators.pattern(this.config.passwordPattern)]),
			ConfirmPassword: new FormControl('', [(c) => Validators.required(c), Validators.pattern(this.config.passwordPattern)]),
		});
		this.usernameForm = new FormGroup({
			Username: new FormControl('', [(c) => Validators.required(c), Validators.minLength(5), Validators.maxLength(50)]),
		});
	}

	ngOnInit(): void {
		// appReady determines if an authenticated connection has been made.
		// While it's waiting it shows a loading icon.  When appReady has a
		// value the loading icon is hidden.  We always want this to be true
		// when you are on the login page.  Because you aren't authed!
		if (this.authService.appReady && !this.authService.appReady.getValue()) {
			this.authService.appReady.next(true);
		}
		const queryParams = this.route.snapshot.queryParams;
		this.resetKey = queryParams['resetKey'];
		this.userId = +queryParams['userId'];
		this.includeUsername = queryParams['username'];
		if (!this.resetKey || !this.userId) {
			this._ngZone.run(() => {
				void this.router.navigate([this.authConfig.paths.homePath]);
			});
		}
	}

	passwordHasError(): boolean {
		const control = this.resetPasswordForm.get('Password');
		return control?.errors && (control.touched || control.errors['maxlength']);
	}

	usernameHasError(): boolean {
		const control = this.usernameForm.get('Username');
		return control?.errors && (control.touched || control.errors['maxlength']);
	}

	showUsernameRequiredError(): boolean {
		const control = this.usernameForm.get('Username');
		return (control?.touched && control.hasError('required')) ?? false;
	}

	showPasswordRequiredError(): boolean {
		const control = this.resetPasswordForm.get('Password');
		return (control?.touched && control.hasError('required')) ?? false;
	}

	showConfirmPasswordRequiredError(): boolean {
		const control = this.resetPasswordForm.get('ConfirmPassword');
		return (control?.touched && control.hasError('required')) ?? false;
	}

	showPasswordMustMatchError(): boolean {
		const control = this.resetPasswordForm.get('Password');
		const controlValue = control?.value;
		const confirmControl = this.resetPasswordForm.get('ConfirmPassword');
		const confirmControlValue = confirmControl?.value;
		return (
			(control?.touched &&
				confirmControl?.touched &&
				(controlValue?.length ?? 0) > 0 &&
				(confirmControlValue?.length ?? 0) > 0 &&
				controlValue !== confirmControlValue) ??
			false
		);
	}

	hasRegexError(): boolean {
		let answer = false;
		const passwordControl = this.resetPasswordForm.controls.Password;
		if (passwordControl.errors?.['pattern']) {
			answer = true;
			passwordControl.setErrors({ pattern: true });
		}
		return answer;
	}

	get password(): string {
		return this.resetPasswordForm.get('Password').value;
	}

	get passwordHasLowercase(): boolean {
		return /[a-z]/.test(this.password);
	}

	get passwordHasUppercase(): boolean {
		return /[A-Z]/.test(this.password);
	}

	get passwordHasNumber(): boolean {
		return /[0-9]/.test(this.password);
	}

	get passwordHasSpecialCharacter(): boolean {
		return /[!$%@#£€*?&]/.test(this.password);
	}

	get passwordIsLongEnough(): boolean {
		return this.password.length >= 12;
	}

	get passwordIsDirty(): boolean {
		return this.resetPasswordForm.get('Password').dirty;
	}

	get usernameIsDirty(): boolean {
		return this.usernameForm.get('Username').dirty;
	}

	get usernameHasInvalidChar(): boolean {
		return /[^a-zA-Z0-9_]/.test(this.usernameForm.value.Username as string);
	}

	get usernameIsTooShort(): boolean {
		return this.usernameForm.value.Username?.length < 5;
	}

	get usernameIsTooLong(): boolean {
		return this.usernameForm.value.Username?.length > 50;
	}

	get disableSave(): boolean {
		const usernameValid =
			!this.includeUsername ||
			(this.includeUsername && this.usernameIsDirty && !this.showUsernameRequiredError() && !this.usernameHasInvalidChar);
		const passwordValid = this.passwordIsDirty && !this.showPasswordRequiredError() && !this.showConfirmPasswordRequiredError();
		return !usernameValid || !passwordValid;
	}

	onSubmit(): void {
		const form = this.resetPasswordForm;
		const usernameForm = this.usernameForm;
		if ((!this.includeUsername || usernameForm.valid) && form.valid) {
			if (this.authService.matchPassword(form, undefined)) {
				if (this.includeUsername) {
					this.authUserService.updateUsername(this.userId, usernameForm.value.Username as string).subscribe({
						next: () => {
							this.savePassword();
						},
					});
				} else {
					this.savePassword();
				}
			} else {
				this.error('Passwords do not match');
			}
		} else {
			markAllFormFieldsAsTouched(usernameForm);
			markAllFormFieldsAsTouched(form);
			this.error(`Please ensure ${this.includeUsername ? 'Username and' : ''} Password meet the requirements`);
		}
	}

	savePassword(): void {
		this.authService
			.resetPassword(this.userId, this.resetPasswordForm.value.Password, this.resetPasswordForm.value.ConfirmPassword, this.resetKey)
			.subscribe({
				next: () => {
					this.success();
					void this.router.navigate([this.authConfig.paths.homePath]);
				},
				error: (error) => {
					if (error.status === 418) {
						this.notificationsService.error(LoginConfig.signInFailure);
					} else if (error.status === 400) {
						if (error.error) {
							if (typeof error.error === 'string') {
								this.error(error.error as string);
							} else if (typeof error.error?.ModelState?.Service === 'string') {
								this.error(error.error.ModelState.Service as string);
							} else {
								this.globalErrorHandler.handleError(error);
							}
						} else {
							this.error('Something went wrong, Please generate a new reset email');
							console.error(`Oops something went wrong!
                        - Probably the key has been used or expired.
                        - Or you did something you weren't supposed to do.
                        - Your best bet is to just generate a new email.`);
						}
					}
				},
			});
	}

	error(msg?: string): void {
		if (!msg) {
			this.notificationsService.error('Save Failed');
		} else {
			this.notificationsService.error(msg);
		}
	}

	success(): void {
		this.notificationsService.success(`${this.includeUsername ? 'Username and' : ''} Password Updated Successfully`);
	}
}
