import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { AuthService } from '@mt-ng2/auth-module';
import { InactivityService } from './services/inactivity.service';

import { environment } from '@environment';
import { Loader } from '@googlemaps/js-api-loader';

@Component({
	selector: 'app-root',
	template: `
		<div [hidden]="appReady === null">
			<div class="app-root-wrapper wrapper">
				<div>
					<ng-progress></ng-progress>
					<router-outlet></router-outlet>
				</div>
			</div>
		</div>
		<div [hidden]="appReady !== null" [style.position]="'relative'">
			<div class="pulse">
				<span></span>
				<img class="logo-img " src="{{ logoFull }}" alt="Logo" />
			</div>
		</div>
	`,
})
export class AppComponent implements OnInit, OnDestroy {
	title = 'app';

	sidebarCollapsed: boolean;
	showNav: boolean;
	showFooter: boolean;
	appReady: boolean = null;
	logoFull = `${environment.assetsPath}logo-full.png`;
	subscriptions: Subscription = new Subscription();

	constructor(private authService: AuthService, private inactivityService: InactivityService, private cdr: ChangeDetectorRef) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.authService.appReady.subscribe((answer) => {
				this.appReady = answer;
				this.cdr.detectChanges();
				this.inactivityService.startWatching();
			}),
		);
		this.loadGoogleMapsApi();
	}

	loadGoogleMapsApi(): void {
		const loader = new Loader({
			apiKey: environment.googleMapsApiKey,
			version: 'weekly',
		});

		void loader.importLibrary('maps');
		void loader.importLibrary('marker');
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
