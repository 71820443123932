<div class="login-box">
    <img class="logo-img" src="{{ logoFull }}" alt="Logo" />
    <ng-container *ngIf="!data">
        <div class="login-box-body">
            <h4>This link has expired. Please request a new one.</h4>
        </div>
    </ng-container>
    <ng-container *ngIf="hasSignature && data">
        <div class="login-box-body">
            <h4>The signature for this encounter has been received, thank you!</h4>
        </div>
    </ng-container>
    <ng-container *ngIf="!hasSignature">
        <div *ngIf="data" class="login-box-body">
            <label>Child(s) Name:</label> {{ data.ChildrenList }}
            <br />
            <label>Date:</label> {{ data.TimeIn | date:'mediumDate' }}
            <br />
            <label>Time In:</label> {{ data.TimeIn | date:'shortTime' }}
            <br />
            <label>Time Out:</label> {{ data.TimeOut | date:'shortTime' }}
            <br />
            <label>Location:</label> {{ data.Location }}
        </div>
        <br />
        <div [style.visibility]="data ? 'visible' : 'hidden'">
            <div class="row">
                <h4>Please sign below</h4>
                <div class="signature">
                    <canvas id="signature-pad" width="400" height="220"></canvas>
                </div>
            </div>
            <br />
            <div class="row">
                <button class="btn btn-flat btn-success" [disabled]="isNotSigned" (click)="addSignature()">Sign</button>
                <button class="btn btn-flat btn-default" (click)="clearSignaturePad()">Clear</button>
            </div>
        </div>
    </ng-container>
</div>