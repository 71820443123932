<main class="login-box">
    <img class="logo-img" src="{{ logoFull }}" alt="Logo" />
    <div class="login-box-body">
        <p class="login-box-msg">
            {{ config.messageOverrides.resetPasswordLabel }}
        </p>
        <form *ngIf="includeUsername" [formGroup]="usernameForm">
            <div class="form-group has-feedback" [class.has-error]="usernameHasError()">
                <label for="username">New Username</label>
                <input
                    #Username
                    type="username"
                    autofocus
                    autocomplete="off"
                    class="form-control"
                    placeholder="Username"
                    formControlName="Username"
                    id="username"
                />
                <div *ngIf="showUsernameRequiredError()" class="small errortext" [style.position]="'block'">
                    Username is required
                </div>
            </div>
            <div *ngIf="usernameIsDirty">
                <div *ngIf="usernameHasInvalidChar" [style.color]="'red'">- Username has invalid character</div>
                <div *ngIf="usernameIsTooLong" [style.color]="'red'">- Cannot be more than 50 characters long</div>
                <div *ngIf="usernameIsTooShort" [style.color]="'red'">- Must be at least 5 characters long</div>
                <br />
            </div>
        </form>
        <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
            <div class="form-group has-feedback" [class.has-error]="passwordHasError()">
                <label for="password">New Password</label
                ><i
                    class="fa fa-info-circle"
                    aria-hidden="true"
                    data-toggle="tooltip"
                    data-html="true"
                    data-placement="right"
                    [title]="config.messageOverrides.failedPattern"
                ></i>
                <input
                    #Password
                    type="password"
                    autofocus
                    autocomplete="off"
                    class="form-control"
                    placeholder="Password"
                    formControlName="Password"
                    id="password"
                />
                <span class="fa fa-lock form-control-feedback"></span>
                <div *ngIf="showPasswordRequiredError()" class="small errortext" [style.position]="'block'">Password is required</div>
                <div *ngIf="showPasswordMustMatchError()" class="small errortext" [style.position]="'block'">Passwords must match</div>
                <div *ngIf="passwordIsDirty">
                    <div [style.color]="!passwordIsLongEnough ? 'red' : 'green'">- Must be atleast 12 characters long</div>
                    <div [style.color]="!passwordHasLowercase ? 'red' : 'green'">- Must have a lowercase letter</div>
                    <div [style.color]="!passwordHasUppercase ? 'red' : 'green'">- Must have an uppercase letter</div>
                    <div [style.color]="!passwordHasNumber ? 'red' : 'green'">- Must have a number</div>
                    <div [style.color]="!passwordHasSpecialCharacter ? 'red' : 'green'">- Must have a special character</div>
                    <br />
                </div>
                <label for="confirm password">Confirm New Password</label>
                <div class="form-group has-feedback" [class.has-error]="showConfirmPasswordRequiredError()">
                    <input
                        #PasswordConfirm
                        type="password"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Confirm Password"
                        formControlName="ConfirmPassword"
                        id="confirm password"
                    />
                    <span class="fa fa-lock form-control-feedback"></span>
                    <div *ngIf="showConfirmPasswordRequiredError()" class="small errortext" [style.position]="'block'">
                        Secondary Password is required
                    </div>
                </div>
                <button [disabled]="disableSave" type="submit" mtDisableClickDuringHttpCalls Class="btn btn-flat btn-success" title="Submit">
                    {{ config.messageOverrides.resetPasswordSaveButtonText }}
                </button>
                <a routerLink="/login" class="btn btn-default pull-right"> {{ config.messageOverrides.resetPasswordBackButtonText }} </a>
            </div>
        </form>
    </div>
    <!-- /.login-box-body -->
</main>
<!-- /.login-box -->
