/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InactivityService } from '../services/inactivity.service';

@Injectable()
export class InactivityInterceptor implements HttpInterceptor {
	constructor(private inactivityService: InactivityService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		this.inactivityService.resetTimer();
		return next.handle(req);
	}
}
