import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IAuthUser } from '@model/interfaces/auth-user';
import { Observable } from 'rxjs';
import { IUpdateUsernamePayload } from '@model/interfaces/custom/update-username-payload';

export const emptyAuthUser: IAuthUser = {
    HasAccess: true,
    Id: 0,
    IsEditable: true,
    Password: null,
    ResetKey: null,
    ResetKeyExpirationUtc: new Date(),
    RoleId: 2,
    Salt: null,
    Username: '',
};

@Injectable({
    providedIn: 'root',
})
export class AuthUserService extends BaseService<IAuthUser> {
    constructor(public http: HttpClient) {
        super('/authUsers', http, [], { entityName: 'Auth User' });
    }

    getEmptyAuthUser(): IAuthUser {
        return { ...emptyAuthUser };
    }

    updateUsername(authUserId: number, username: string): Observable<void> {
        return this.http.post<void>(`/authUsers/username`, { AuthUserId: authUserId, Username: username} as IUpdateUsernamePayload);
    }
}
