// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=testing` then `environment.testing.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
	apiVersion: '1',
	appName: 'NYTPS',
	appVersion: '1.0.0',
	assetsPath: 'assets/',
	authClientId: 1,
	baseApiUrl: '',
	docPath: 'docs/',
	googleApiKey: '', // Empty this string to disable google login
	googleMapsApiKey: 'AIzaSyCtcct2rQdbdKWmNwP30DY4-ARiv6kMAYA',
	imgPath: 'docs/images/',
	logger: false,
	msAuthConfig: null,
	production: false,
	siteName: 'nYTPS',
	submitHelpUrl: 'http://submit-help.testing.milesapp.com',
	logoutTimer: 8 * 60 * 60 * 1000, // 8 hours
};
